import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import RedirectionToSalestool from "./MainPage/RedirectionToSalestool/RedirectionToSalestool";
import { createBrowserHistory } from "history";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateNewFileCard from "./CreateFileCard/CreateNewFileCard";
import FileCardList from "./FileCardList/FileCardList";
import MainPage from "./MainPage/MainPage";
import PageNotAvailable from "./MainPage/PageNotAvailable/PageNotAvailable";
import ResendSigning from "./ResendSigning/ResendSigning";
import { isFileCardListUrl } from "./utils/QueryStringUtil";
import Signing from "./Signing/Signing";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

var reactPlugin = new ReactPlugin();

const browserHistory = createBrowserHistory({ basename: "" });
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    isCookieUseDisabled: true,
    isStorageUseDisabled: true,
  },
});

if (process.env.REACT_APP_APPLICATION_INSIGHTS) {
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer((envelope) => {
    if (envelope && envelope.tags) {
      envelope.tags["ai.cloud.role"] = "BESTCard Frontend";
      envelope.tags["ai.cloud.roleInstance"] = "BESTCard Frontend";
    }
  });
} else {
  console.log("Application Insights NOT loaded");
}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className={isFileCardListUrl() ? "" : "main-container"}>
        <Switch>
          <Route exact={true} path="/create" component={RedirectionToSalestool} />
          <Route exact={true} path="/createnewfilecard" component={CreateNewFileCard} />
          <Route exact={true} path="/resend/signing" component={ResendSigning} />
          <Route exact={true} path="/admin/list" component={FileCardList} />
          <Route exact={true} path="/admin/edit/:id" component={MainPage} />
          <Route exact={true} path="/admin/signing/:id" component={Signing} />
          <Route exact={true} path="/admin/:id" component={MainPage} />
          <Route exact={true} path="/:id" component={MainPage} />
          <Route path="*" component={PageNotAvailable} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
